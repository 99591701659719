/* body {
  background-image: url(/background.jpeg);
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */
html::before {
  content: " ";
  display: block;
  background-image: url(/background.jpeg);
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}
