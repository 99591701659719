.childhood,
.teenager,
.adult,
.retired,
.tooOld,
.youarehere {
  width: 10px;
  height: 10px;
  margin: 0.5px 0.5px 0.5px 0.5px;
  border-radius: 40px;
}
.legendeText {
  font-size: 10px;
}
.legendeContainer {
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-direction: row;
}
.legendeBigContainer {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}
@media (max-width: 700px) {
  .childhood,
  .teenager,
  .adult,
  .retired,
  .tooOld,
  .youarehere {
    width: 6px;
    height: 6px;
  }
  .legendeText {
    font-size: 8px;
  }
}
.childhood {
  background-color: #eeeee4;
}
.teenager {
  background-color: #76b5c5;
}
.adult {
  background-color: #1e81b0;
}
.retired {
  background-color: gold;
}
.tooOld {
  background-color: #eeeee4;
}
.youarehere {
  outline-color: aliceblue;
  background-color: skyblue;
  outline-width: 1px;
  outline-style: solid;
}
